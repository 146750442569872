import * as axios from 'axios';
import { API, API_TIMEOUT } from './config';

//export const inputDateFormat = 'YYYY-MM-DD';
var errorMessage ='';

const getErrorMessage = function(){
    return errorMessage;
}

const hasError = function()
{
    return errorMessage !== '';
}
////////////////////////////////////////////////////////////
/////////// GETTERS ////////////////
const getInvitedEvents = async function()
{
    console.log("Get invited events")
    var url = API + "events";
    return getData(url);
};

const getConfirmedEvents = async function()
{
    var url = API + "events/confirmed";
    return getData(url);
}

const getEventInfo = async function(eventId)
{
    var url = API + "events/info/" + eventId;
    return getData(url);
}

const getAttendance = async function()
{
    var url = API + "attendance";
    return getData(url);
}

const getMemberAttendance = async function(memberId)
{
    var url = API + "attendance/id/"+memberId;
    return getData(url);
}

const getAttendanceReport = async function(eventId)
{
    var url = API + "attendance/report/eventId/"+ eventId;
    return getData(url);
}

const getMemberDetails = async function()
{
    var url = API + "members";
    return getData(url);
}

const getImageNames = async function()
{
    var url = API + "gallery";
    return getData(url);
}
const getData = async function(url)
{
    errorMessage = '';
    console.log('getting data for ' + url);
    try 
    {
        let res = await axios.get(url, { 
                    timeout: API_TIMEOUT,
                    headers: {
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0', 
                    },});
        if(res.status == 200)
        {
            let data = res.data;
            console.log("response = " + data);
            return data;       
        }
        else
        {
            throw Error(res);
        }
    } 
    catch (error)
    {
        handleError(error);
        return [];
    }
}


//////////////////////////////////////////////////
/////////////////////////////////////////////////
////// POSTS /////////////
const postAttendance = async function(memberId, attendance)
{
    var url = API + "attendance/id/"+memberId;
    var events={
        events : attendance
    };

    return postData(url, events);
}
const postEventUpdate = async function(event)
{
    var url = API + "events/edit";
    return postData(url, event);
}

const postEventAdd = async function(event)
{
    var url = API + "events/add";
    return postData(url, event);
}

const postImages = async function(event)
{
    var url = API + "gallery";
    return postData(url, event);
}

const postSubsPaidStatus = async function(memberId, subsPaid)
{
    var data = {"memberId" : memberId,
                "subsPaid" : subsPaid};
    var url = API + "members/subsPaid";
    return postData(url, data);
}
const postAddMemberData = async function(memberData)
{
    var url = API +"members/add";
    return postData(url, memberData);
}
const postEditMemberData = async function(memberData)
{
    var url = API + "members/edit";
    return postData(url, memberData);
}
const postUpdateConfirmedStatus = async function(eventId, status)
{
    var url = API + "events/confirm/"+eventId+"/"+status;
    var data = {};
    return postData(url, data);
}
const postData = async function(url, data)
{    
    console.log("url = " + url + " data = " + JSON.stringify(data));
    errorMessage = '';
    console.log("After clear error message");
    try 
    {
        let resp = await axios.post(url, data,  { timeout: API_TIMEOUT});
        console.log("Post status = " + resp.status);
        if(resp.status == 201)
        {
            console.log("Successfully posted update");
            return true;
        }
        else
        {            
            throw Error(resp);
        }
    } 
    catch (error) 
    {
        return handleError(error);
    }
}
//////////////////////////////////////////////////
/////////////////////////////////////////////////
////// DELETE /////////////
const deleteOldEvents = async function()
{
    const url = API + "events/historic";
    return deleteData(url);
}

const deleteMember = async function(memberId)
{
    const url = API + "members/" + memberId;
    return deleteData(url);
}

const deleteEvent = async function(eventId)
{
    const url = API + "events/" + eventId;
    return deleteData(url);
}
const deleteData = async function(url, data = {})
{    
    console.log("url = " + url + " data = " + data);
    errorMessage = '';
    console.log("Cleared error message");
    try 
    {
        let resp = await axios.delete(url,  { timeout: API_TIMEOUT});
        console.log("Delete status = " + resp.status);
        if(resp.status == 201 || resp.status == 200)
        {
            console.log("Successfully deleted event(s)");

            return true;
        }
        else
        {            
            throw Error(resp);
        }
    } 
    catch (error) 
    {      
        return handleError(error);
    }
}
export const data = {
    getErrorMessage,
    hasError,
    getInvitedEvents,
    getConfirmedEvents,
    getEventInfo,
    getAttendance,
    getMemberAttendance, 
    getAttendanceReport,
    getMemberDetails,
    getImageNames,
    postAttendance,
    postEventUpdate,
    postEventAdd,
    postImages,
    postSubsPaidStatus,
    postAddMemberData,
    postEditMemberData,
    postUpdateConfirmedStatus,
    deleteOldEvents,
    deleteMember,
    deleteEvent
    };


////////////////////////////
//// General Functions
////////////////////////////
const handleError = function(error)
{
    if(error.response)
    {
        errorMessage = error.response.data;
        console.log(errorMessage);
    }
    else
    {
        errorMessage =  error.message;
        console.log(error);
    }
    return false;
}