<template>
       
        <Attendance></Attendance>
    
</template>

<script>

import Attendance from './../components/attendanceOverview'

export default {
    name: 'Members',
    components: {        
        Attendance     
    },
    data() {
        return {
            
        }
    },
 
}
</script>