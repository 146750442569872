// consider being able to add/display commetns like excel
<template>
<div >
    <div class="container">     
        <div class="title titleSingle">
            <div></div>
            <div>
                <h3 class="titleText">
                    Invited Events
                </h3>
            </div>
            <div></div>
        </div>
        <div class="displayTextbox" style="height:auto">    
        <v-data-table 
            :headers="headers"
            :items="tableData" 
            height="55vh"
            @click:row="gotoIndividualAttendance"
            :mobile-breakpoint=0
            hide-default-header
            fixed-header
            hide-default-footer
            disable-pagination
            :loading="loadingEvents" loading-text="Loading invited events... Please wait">  
            <template v-slot:item.name="{ item }">
                <td>
                <span ><b class="memberName" :class="{subsPaid: item.subsPaid}"> {{ item.name }} </b></span>
                </td>
            </template>          
            <template v-slot:header="{ props }">                 
                <tr style="position:sticky; z-index:2; top:0; box-shadow:  0px 5px 10px -2px grey; border-radius: 5px; background:white; font-size:1.2em;">
                        <th v-for="header in props.headers" :key="header.text" :class="{confirmedEvent: header.confirmed}" >
                            <template v-if="header.divider === true">
                                <td >{{header.text}}</td>
                            </template>
                            <template v-else>
                                <td class="headerCol" @click="onHeaderClick(header.value)">
                                    <i class="mdi mdi-information-outline" style="cursor:pointer"> {{header.text}}</i>
                                </td>
                            </template>
                        </th>
                    </tr>
                    <tr style="border-spacing: 5px;">                    
                        <td v-for="header in props.headers" :key="header.text" style="text-align:center; padding: 10px 1px 8px 1px;">
                            <span v-if="header.divider === true"></span>
                            <span v-else style="font-size:1.1em;">{{header.friendlyDate}}</span>
                        </td>
                    </tr>                    
                    <tr style="box-shadow:  0px 5px 10px -10px grey; border-radius: 2px; background:white;">
                        <td v-for="header in props.headers" :key="header.text" >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" style="display:inline-block; color:green; font-size:1em">                                      
                                        {{header.dancer}}
                                    </span>
                                </template>
                                <span>Dancers</span>
                            </v-tooltip>
                            <span v-if="header.divider === true"></span>
                            <span v-else style="font-size:1em"> - </span>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" style=" font-size:1em; display:inline-block; color:purple;">                                      
                                        {{header.music}}
                                    </span>
                                </template>
                                <span>Musicians</span>
                            </v-tooltip>
                        </td>
                    </tr>
            </template>      
      
        </v-data-table>   
        <div class="alert alert-primary" style="text-align:center" role="alert" v-show="message">{{ message }}</div>
    <div class="alert alert-danger" style="text-align:center" role="alert" v-show="errorMessage">{{ errorMessage }}</div>    
        </div>
    </div>
    <div id="overlayAdditionalInfo" @click.self="onCloseOverlay" v-if="showOverlay">
        <event-info :eventId="eventId" @click="onCloseOverlay"></event-info>
    </div>
    </div>
</template>

<script>

import { data } from "../shared/data";
import { display } from "../shared/display";
import EventInfo from './../components/EventInfo'

export const MAX_TITLE_LENGTH = 20;

export default {
    name: "Attendance",
    components: {
        EventInfo,
    },
    data() {
        return {
            headers: [],
            tableData:[],
            events: [],
            attendees: [],
            eventId:-1,
            message: '',
            errorMessage: '' ,
            loadingEvents: false,
            page: 1,
            pageCount:  0,
            showAdditionalInfo: false,
            MAX_TITLE_LENGTH                
        }
    },
    computed: {
        showOverlay() {
            return this.showAdditionalInfo 
        }
    },
    async created() {
        console.log("loading attendance");
        this.loadingEvents = true;
        await this.loadAttendance();
    },
    methods: {
        async loadAttendance() {
            this.events = [];
            this.message = "Please wait: Retrieving events";
            this.errorMessage = '';
            console.log("requesting events");

            var event = await data.getAttendance();
            this.loadingEvents = false;
            if(data.hasError())
            {                
                this.message = '';
                this.errorMessage = "ERROR - " + data.getErrorMessage();                
                return;
            }
        
            this.events = event.attendanceOverview[0].events;
            this.attendees = event.attendanceOverview[1].attendee;
            
            if (this.events.length <= 0) 
            {
                this.message = "The team currently have no invited events.";
                return;
            }
            this.message = '';
            this.createTableHeaders();
            this.createTableData();
        },
        createTableHeaders(){
            var attendeeHeader = {
                text: "Name",
                value: "name",
                divider: true,
                confirmed: false,
                align: 'center'      
            };
            this.headers.push(attendeeHeader);

            for(var i = 0; i < this.events.length; i++)
            {
                var event = this.events[i]; 
                var date = display.getFriendlyDate(event.startDate, 'dd/MM/yy');
                if(event.endDate !== null && event.endDate !== event.startDate)
                {           
                    if(display.hasSameMonth(event.startDate, event.endDate))
                    {
                        date = display.getFriendlyDate(event.startDate, 'dd') + ' - ' +
                                display.getFriendlyDate(event.endDate, 'dd/MM/yy'); 
                    }
                    else
                    {
                        date += ' - ' + display.getFriendlyDate(event.endDate, 'dd/MM/yy'); 
                    }      
                    
                }  
                var musicianCount = event.musicianCount == null ? 0 : event.musicianCount;
                var dancerCount = event.dancerCount == null ? 0 : event.dancerCount;
                var confirmed = event.eventConfirmed == 1 ? true : false;
                var title = this.ellipseTitle(event.title);
                var header = {
                    text: title,
                    value: event.eventId.toString(),
                    sortable: false,
                    music: musicianCount,
                    dancer: dancerCount,
                    friendlyDate: date,
                    confirmed : confirmed,
                    align: 'center'           
                };
                this.headers.push(header);
            }
        },
        createTableData(){            
            for(var row = 0; row < this.attendees.length; row++)
            {
                var attendee = this.attendees[row];
               
                var data = {
                    name: attendee.name,
                    id: attendee.memberId,
                    subsPaid: attendee.subsPaid,
                };
                
                var attendance = attendee.attendance;
                for(var col = 0; col < attendance.length; col++)
                {
                    var eventId = attendance[col].eventId.toString();
                    var attending = attendance[col].attending == null ? '?' : attendance[col].attending;
                    data[eventId] = attending;
                }
                this.tableData.push(data);
            }
            
        },
        gotoIndividualAttendance(rowData){
            var id = rowData.id;
            var name = rowData.name;
            console.log('load data for id:'+ id + ', Name:'+name);
            this.$router.push({name: 'Attendance', params:{memberId : id, memberName: name}});
        },
        onHeaderClick(event){
            console.log("eventId = " +event);
            this.eventId = event;
            this.showAdditionalInfo = true;
            console.log("header clicked = " + this.eventId);
        //    document.getElementById("overlayAdditionalInfo").style.display = "block";            
           // this.$router.push({name: 'EventInfo', params:{eventId : event}});
        },
        onCloseOverlay()
        {
            this.showAdditionalInfo = false;
            console.log("close overlay = " + this.showAdditionalInfo);
            document.getElementById("overlayAdditionalInfo").style.display = "none";
        },
        ellipseTitle(title)
        {
            var ellipsedTitle = title;
            if(title.length > MAX_TITLE_LENGTH)
            {
                ellipsedTitle = title.substring(0, MAX_TITLE_LENGTH-3) + '...';
            }
            return ellipsedTitle;
        }      
    },    
}
</script>

<style lang="scss" scoped>
@import "./../design/attendance.scss";

</style>