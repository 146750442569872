<template>
    <div class="containerTop">
        <div class="divAlign">      
            <v-row>        
                <v-col cols="12">
                    <v-card class="pa-3">
                        <v-card-title class="card-header py-2 mx-3 my-0 mb-4 d-flex justify-content-between rounded align-items-center">
                            <v-spacer></v-spacer>
                            <h3 class="titleText">{{name}}'s Details</h3>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field label="Name" v-model="name" ref="name"></v-text-field>
                            <v-text-field label="Email" v-model="email" ref="email"></v-text-field>
                            <v-select :items="typeOptions" label="Member Type" v-model="memberType" item-text="type" item-value="keys"></v-select>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="blue-grey" elevation="10" @click="onBack">Back</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="green" elevation="10" @click="onSubmit">Submit</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>        
    </div>
</template>

<script>
import { data } from "../shared/data";

export default {
    name: 'addEditMember',
    data() {
        return {
            memberId:0,
            name: '',
            email: '',
            memberType:'',            
            typeOptions:[{ type:'Dancer', key: 1},
                        {type: 'Musician', key: 2}],
            displayTypes:[{key: 1, value: 'Edit'},
                        {key: 2, value: 'Add'}],
            currentDisplay: 2
        }
    },
    async created()
    {
        var type = this.$route.params.type;
        if (type === "edit") 
        {
            this.currentDisplay = 1;
            var memberData = this.$route.params.member; 
            this.memberId = memberData.memberId;
            this.name = memberData.name;
            this.email = memberData.email;
            var memberType = memberData.type.toLowerCase();
            if(memberType == 'dancer')
            {
                this.memberType = 'Dancer';
            }
            else if(memberType == 'musician')
            {
                this.memberType = 'Musician';
            }
        }
    },
    methods: {
        onBack() {
            if(this.currentDisplay == 1) // edit
            {
                this.$router.push({name: 'MembersList'});
            }
            else // add
            {
                this.$router.push({name: 'Admin'});
            }            
        },
        async onSubmit()
        {
            if(this.validSubmitData())
            {
                var member =
                {              
                    memberId:   this.memberId,  
                    name:       this.name,
                    email:      this.email,
                    type:       this.memberType
                };
                var success;
                if(this.currentDisplay == 1) //edit
                {
                    success = await data.postEditMemberData(member);
                }
                else //add
                {
                    success = await data.postAddMemberData(member);
                }
                
                if(success)
                {
                    this.$confirm("Member data successfully " + (this.currentDisplay == 2 ? 'added' : 'updated'), 'Success', 'success');
                    this.$router.push({ name: "Admin" });
                }
                else
                {
                    var msg = "Member data "+ (this.currentDisplay == 2 ? "creation " : "updated") +" failed ";
                    this.$confirm(msg, 'Error', 'error');
                    console.log("Member data updated failed - " + data.getErrorMessage());
                }
            }
        },
        validSubmitData()
        {
            if(this.name.length < 2)
            {
                this.$confirm("The members name must be longer than 2 characters", "Error", "error");
                return false;
            }
            else if(this.name.length > 20)
            {
                this.$confirm("The members name must be shorter than 20 characters", "Error", "error");
                return false;
            }
            return true;
        }
    },
}
</script>

<style lang="scss">
.divAlign {
  margin: 0 auto;
  width: 50%;
}
.containerTop {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>