<template>
  <div class="container">
    <div class="title">
      <div></div>
      <div>
        <h3 class="titleText">
          Welcome to <br />Mary Rose and Royal George Morris
        </h3>
      </div>
      <div></div>
    </div>
    <div class="displayTextbox">
      <div class="textBlocks">
        <v-img
          :src="require('../images/HomePage.jpg')"
          class="welcomeImg .d-none .d-sm-flex"
        ></v-img>
        <p v-html="intro">{{ intro }}</p>
      </div>
      <div class=".d-none .d-sm-flex"></div>
      <div class="textBlocks">
        <h1>{{ originTitle }}</h1>
        <p v-html="originText">{{ originText }}</p>
      </div>
      <br />
      <div class="textBlocks">
        <h1>{{ historyTitle }}</h1>
        <p v-html="historyText">{{ historyText }}</p>
      </div>
    </div>
  </div>
</template>

<style >
@import "./../design/general.scss";

.welcomeImg {
  position: relative;
  float: left;
  max-width: 30% !important;
  height: auto;
  width: auto;
  padding: 0px 10px 20px 0px;
}

h1 {
  font-size: 2.5vmax;
  font-weight: bold;
}
</style>
<script>
export default {
  name: "Home",
  data() {
    return {
      title: "Welcome",
      intro:
        "Mary Rose and Royal George are a Portsmouth based dancing team, who aim to bring a lively new approach to the entertaining morris style of dancing. Their unique style takes its inspiration from a number of morris traditions including, Border, Cotswold, Molly and Rapper.  These influences added with their own unique flair make them a true pleasure to watch. <br/> Established in 2015 they are building a fantastic reputation for themselves as a team of unique dancers who are fun and enjoyable to watch and socialise with.",
      originTitle: "Team Origin",
      originText:
        "The team’s name is taken from two ships that were sunk in the Solent. The first, 'The Mary Rose' is a famous carrack-type warship that served in King Henry VIII navy for 33 years and is now on display in the Portsmouth Historic Dockyard Museum.  The second a lesser known ship 'The Royal George', was a 100 gun first rate ship of the royal navy. Together these two ships create the teams name 'Mary Rose and Royal George'.<br><br>As with the name of the team all of the dances they perform are named after areas or events within Portsmouth's historic city. With our costume being a mixture of traditional folk and traditional work wear. The woman wear layers of skirts and a headscarf, whilst the men wear braces and cloth caps.",
      historyTitle: "Team History",
      historyText:
        "Mary Rose and Royal George are a revival of two teams that folded in the early 90s.  The original teams named Royal George and Mary Rose where separate male/female teams that practiced, performed and socialised together. The teams started rehearsing in 1981, with many of their dances being choreographed by Sue Stevens (now deceased) with help from Nigel Churchill, Russ Randell and Simon and Jo Harmer. The team danced out for the first time in 1982 at The Thatched House in Locksway Road.",
      computer: false,
    };
  },
};
</script>
