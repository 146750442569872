import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { API, API_TIMEOUT } from '../shared/config';

Vue.use(Vuex)

//export default new Vuex.Store({
const store = new Vuex.Store({

    state(){
        return{
            count: 0,
            status: 'loggedOut',
            admin: localStorage.getItem('admin') || false,
            loggedIn: localStorage.getItem('loggedin') || false,
            errorLog: '',
        }
    },
    mutations: {
        auth_request(state) {
            state.status = 'Loading'
        },
        auth_success(state, isAdmin) {
            state.status = 'success'
            state.admin = isAdmin;
            state.loggedIn = true;
            localStorage.setItem('loggedin', true);
            localStorage.setItem('admin', isAdmin);
        },
        auth_failed(state, errorLog) {
            state.status = 'failed';
            state.errorLog = errorLog;
            state.loggedIn = false;
        },
        auth_error(state, errorLog) {
            state.status = 'error';
            state.errorLog = errorLog;
            state.loggedIn = false;
        },
        logout(state) {
            state.status = '';
            state.loggedIn = false;
            state.errorLog = '';    
            state.admin = false;                
        }

    },
    getters: {
        isLoggedIn: state => state.loggedIn,
        authStatus: state => state.status,
        hasError: state => !!state.errorLog,
        getError: state => state.errorLog,
        getAdminStatus: state => state.admin,
    },
    actions: {
        async login({ commit }, user) {
            var url = API + "login/" + user.loginDetails.username + "/" + user.loginDetails.password;

            commit('auth_request');
            try {
                let res = await axios.get(url, { timeout: API_TIMEOUT });
                if (res.status === 200) {
                    let data = res.data;
                    console.log("login responded" + data.result.loginSuccess);
                    if (data.result.loginSuccess == true) 
                    {
                        console.log("successful logn");
                        let result = data.result;
                        if (result.admin === true)
                        {
                            localStorage.setItem('admin', true);
                            //store token in local storage so user can leave app and return within valid timescale and still be logged in                    
                            axios.defaults.headers.common['Authorization'] = true;
                            console.log("Admin, authorise success");
                            commit('auth_success', true, true); //set as admin
                            console.log("state = ", store.state.status);
                        }
                        else {

                            console.log("Not admin, authorise success");
                            commit('auth_success', false, true);
                        }
                    }
                    else if (data.result.loginSuccess == false) 
                    {                        
                        var error = data.result.message;
                        commit('auth_failed', error);
                        localStorage.setItem('loggedIn', false);
                    }
                    else {
                        throw Error('Invalid state reponse');
                    }
                }
                else {
                    console.log("Got error status = " + res.status);
                    throw Error(res);
                }
            }
            catch (error) {
                console.log("login failed, msg = " + error.message);
                commit('auth_error', error.message);
                localStorage.setItem('loggedIn', false);
            }
        },
        logout({ commit }) {
            commit('logout');
            localStorage.setItem('loggedIn', false);
            delete axios.defaults.headers.common['Authorization'];
        }
    }
});

export default store;