<template>
    <div class="placement">       
        <div class="gallery" id="gallery"></div>
    </div>
    
</template>

<script>
export default {
  name: "customGallery",
  data() {
      return {          
          lightboxOpen: true          
      }      
  },
  props: {
          images: {
              type: Array,
              required: true
          },
      },
  mounted() {
      this.loadImages();
      this.afterImageLoad();   
  },
  methods: {  
      loadImages()
      {
          for(var i = this.images.length -1; i >= 0; --i)
          {
              this.loadImage(this.images[i], i);
          }
      },
      loadImage(src, index)
      {
          var gallery = document.querySelector("#gallery");
            
          var img = document.createElement("img");          
          img.setAttribute("src", src);
          img.setAttribute("data", index);
          img.setAttribute("alt", "");

          var content = document.createElement("div");
          content.setAttribute("class", "content");
          var galleryItem = document.createElement("div");
          galleryItem.setAttribute("class", "gallery-item");

          content.appendChild(img);
          galleryItem.appendChild(content);
          gallery.appendChild(galleryItem);

      },
    afterImageLoad()
     {
      var gallery = document.querySelector("#gallery");     

      gallery.querySelectorAll("img").forEach((item)  =>
      {
        item.classList.add("byebye");
        if (item.complete) 
        {   
            this.onImagLoaded(gallery, item);
            console.log(item.src);
        } 
        else 
        {
            item.addEventListener("load", () =>
            {
                this.onImagLoaded(gallery, item);
            });
        }
      });
      window.addEventListener("resize", () => {this.resizeAll(gallery)} );
      var galleryItems = gallery.querySelectorAll(".gallery-item");
      galleryItems.forEach((item) =>
      {
        if(item)
        {            
            item.addEventListener("click", (e) =>{
                  
                var img = e.currentTarget.firstChild.firstChild;
                var index = img.getAttribute('data');                
                console.log("go to lightbox with item loaded, " + index);   
                this.$emit("click", index);
                
            });
        }
        

        /*item.addEventListener("click", function () {
          item.classList.toggle("full"); 
        }, );*/
      });
    },

    onImagLoaded(gallery, item){
        var altura = this.getVal(gallery, "grid-auto-rows");
        var gap = this.getVal(gallery, "grid-row-gap");
    
        var gitem = item.parentElement.parentElement;
        gitem.style.gridRowEnd =
            "span " +
            Math.ceil(
                (this.getHeight(gitem) + gap) / (altura + gap)
            );
        item.classList.remove("byebye");
    },
    getVal(elem, style) {
      return parseInt(window.getComputedStyle(elem).getPropertyValue(style));
    },
    getHeight(item) {
      return item.querySelector(".content").getBoundingClientRect().height;
    },
    resizeAll(gallery) {
      var altura = this.getVal(gallery, "grid-auto-rows");
      var gap = this.getVal(gallery, "grid-row-gap");
      gallery.querySelectorAll(".gallery-item").forEach((item) => {
        var el = item;
        el.style.gridRowEnd =
          "span " +
          Math.ceil(
            (this.getHeight(item) + gap) / (altura + gap)
          );
      });
    },
    addLightboxEventListeners() {
      var lightbox = document.querySelector("#lightbox");
      // close lightbox when clicking on background
      lightbox.find(".lightbox-slide").on("click", (e) => {
        if (e.currentTarget == e.target && !this.wasSwiping) this.closeLightbox();
      });

      // close lightbox when clicking on close button
      lightbox.find(".lightbox-close").on("click", () => {
        this.closeLightbox(lightbox);
      });
    },
    openLightbox(){
       // var lightboxWrapper = document.querySelector("#lightbox-wrapper");
       
       // var lightbox = document.querySelector("#lightbox");        
        //lightbox.addClass('open');
       // lightboxWrapper.setAttribute("display", "block");
        
        
        //lightbox.parent('.lightbox-wrapper').fadeIn('fast');
       // lightboxWrapper.fadeIn('fast');

        //currentItem = null;
        /*var currentImg = currentItem.find('.lightbox-image');
        currentImg.show();*/
    },
    closeLightbox(lightbox) {
        this.lightboxOpen = true;
     /* var lightboxWrapper = document.querySelector("#lightbox-wrapper");
      var lightboxImage = document.querySelector("#lightbox-image");

      // close lightbox
      lightboxWrapper.removeClass("open").fadeOut("fast", () => {
        lightboxImage.attr("src", "");
        lightboxImage.attr("srcset", "");
      });*/

      // remove lightbox event listeners
      lightbox.find(".lightbox-slide").off();
      lightbox.find(".lightbox-close").off();
      lightbox.find(".lightbox-arrow").off();
      document.off("keydown.lightbox");
    }
   
  },
};
</script>

<style>
@import "./../design/customGallery.css";
</style>