<template>
    <Individual/>
</template>

<script>
import Individual from './../components/indivdiualAttendance'
export default {
    name: "Attendance",
    components: {       
        Individual
    },
}
</script>

<style>

</style>