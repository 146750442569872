import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/authStore'
import Axios from 'axios'
import vuetify from './plugins/vuetify';
import { BootstrapVue, NavbarPlugin, TablePlugin} from 'bootstrap-vue'
import VueSimpleAlert from 'vue-simple-alert';
import Meta from 'vue-meta'


Vue.use(TablePlugin)
Vue.use(NavbarPlugin)
Vue.use(BootstrapVue)
Vue.use(VueSimpleAlert)
Vue.use(Meta)




//const facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID;

Vue.config.productionTip = false ////????? What is this for????

Vue.prototype.$http = Axios;
const loggedIn = localStorage.getItem('loggedin');
if(loggedIn)
{
  Vue.prototype.$http.defaults.headers.common['Authorization'] = loggedIn;
}

//initFacebookSdk();
startApp();

function startApp()
{
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');

    this.$http.interceptors.response.use(undefined, function(err){
    if(err.status === 401 && err.config && !err.config.__isRetryRequest){ // may need refining to include which page we are on 
      this.$store.dispatch('logout');
    }
    throw err;
  }); 
}



