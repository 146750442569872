
import {format, parseISO, isValid, compareAsc, compareDesc, isSameMonth} from 'date-fns'

const weekdays = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 4,
  THURSDAY: 8,
  FRIDAY: 16,
  SATURDAY: 32,
  SUNDAY: 64,
};

const friendlyDateFormat = 'eee do MMM yyyy'

const getFriendlyDate = function(value, dateFormat = friendlyDateFormat)
{    
    var date = parseISO(value);
    if(isValid(date))
    {
      return format(date, dateFormat);
    }
    else
    {
      return date;
    }
}


const hasSameMonth = function(startDate, endDate)
{
    var date1 = parseISO(startDate);
    var date2 = parseISO(endDate);
    if(isValid(date1) && isValid(date2))
    {
      return isSameMonth(date1, date2);
    }
    else
    {
      return false;
    }     
}


const sortDate = function(a,b,key, sortDesc) 
{
    if(key !== 'startDate' && key !=='endDate'){
      return false;
    }
    let aDate = parseISO(a[key]);
    let bDate = parseISO(b[key]);
    console.log('date = ' + aDate);
    console.log('sort = ' + sortDesc);
    if(sortDesc !== true)
    {
      let result = compareDesc(aDate, bDate);
      console.log('compare desc' + result);
      return compareDesc(aDate, bDate);
    }
    else
    {
      let result = compareAsc(aDate, bDate);
      console.log('compare asc' + result);
      if(result == -1)
        return 1;
      else if(result == 1)
        return -1;        
      else        
        return 0;
    }  
  };
  const getWeekDayKey = function(strWeekDay)
  {
    strWeekDay = strWeekDay.toLowerCase();
    switch(strWeekDay)
    {
       case 'monday':
         return 1;
       case 'tuesday':
         return 2;
        case 'wednesday':
          return 4;
        case 'thursday':
          return 8;
        case 'friday':
          return 16;
        case 'saturday':
          return 32;
        case 'sunday':
          return 64;  
    }
  };

  const getDayOfTheWeekString = function(flag) {
    if (flag & weekdays.MONDAY) {
      return "Monday";
    } else if (flag & weekdays.TUESDAY) {
      return "Tuesday";
    } else if (flag & weekdays.WEDNESDAY) {
      return "Wednesday";
    } else if (flag & weekdays.THURSDAY) {
      return "Thursday";
    } else if (flag & weekdays.FRIDAY) {
      return "Friday";
    } else if (flag & weekdays.SATURDAY) {
      return "Saturday";
    } else if (flag & weekdays.SUNDAY) {
      return "Sunday";
    }
  };


export const display ={
    getFriendlyDate,
    hasSameMonth,
    sortDate,
    getWeekDayKey,
    getDayOfTheWeekString
};
