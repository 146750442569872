<template>
  <div class="col d-flex justify-content-center">
      <b-card style="max-width: 20rem;" title="Members Login" class="mt-5 text-center shadow-lg" border-variant="light" bg-variant="light">
        <b-form v-if="show" v-on:keyup.enter="login()">
          <b-form-input v-model="user.username" required placeholder="Enter username"></b-form-input>
          <br>
            <b-form-input type="password" v-model="user.password" requried placeholder="Enter password"></b-form-input>
            <small class="text-danger">{{ message }}</small>
        </b-form>
        <br>
        <div style="align:center" v-if="show">
          <b-button class="mr-2" variant="primary" @click="login()">Login</b-button>
          <b-button class="mr-2" variant="danger" @click="reset()">Reset</b-button>
        </div>
        <div v-if="!show">
            <b-spinner v-if="!show"></b-spinner>            
            <label style="padding-left:10px">Checking login details...</label>
        </div>
        
    </b-card>        
    
  </div>
</template>

<script>

export default {
    name: 'Login',
    data() {
        return {
            user:{            
                username: null,
                password: null
            },
            show:true,
            message: ''
        }      
    },
    components: {        
        
    },
    methods: {
        async login() 
        {
            //auth.clearLogin();
            if(this.canLogin())
            {
                this.show = false;  
                let loginDetails = this.user;                
                await this.$store.dispatch('login', {loginDetails})
                
                if(this.$store.state.loggedIn)
                {
                    this.$router.push('/members');
                }
                else
                {
                    this.show = true;
                    this.message = this.$store.getters.getError;
                }
            }                     
        },
        reset()
        {
            this.user.username = null;
            this.user.password = null;
        },
        canLogin()
        {
            console.log('username = ' + this.user.username + ", password = " + this.user.password);                 
            if(this.user.username === null)
            {
                this.message = "Username is required";
                return false;
            }
            else if(this.user.password === null)
            {
                this.message = 'Password is required';
                return false;
            }
            else
            {
                return true;
            }            
        }
    },
}
</script>

<style>
.loginCard
{
    background-color: grey;
}
</style>
