<template>    
    <ConfirmedEvents />
</template>

<script>
import ConfirmedEvents from './../components/confirmedEvents'

export default {
    name: "EventsPage",
    components: {        
        ConfirmedEvents
    },
}
</script>