<template>  
  <b-navbar type="dark" toggleable="md" variant="dark" fixed="top" rounded>    
    <b-navbar-brand href="index.html">
      <div class="teamName">
      <img class="Logo" :src="require('../images/MrrgLogo.png')" />
      <span> Mary Rose & Royal George</span>
      </div>
    </b-navbar-brand>    
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse"  is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item><span v-if="isAdmin" class="admin"><a @click="goToAdmin">Admin</a></span></b-nav-item>
        <b-nav-item><router-link to="/Home" tag="li">Home</router-link></b-nav-item>
        <b-nav-item><router-link to="/About" tag="li">About</router-link></b-nav-item>
        <b-nav-item><router-link to="/Events" tag="li">Events</router-link></b-nav-item>
        <b-nav-item><router-link to="/Gallery" tag="li">Gallery</router-link></b-nav-item>
        <b-nav-item><router-link to="/Members" tag="li">Members</router-link></b-nav-item>
        <b-nav-item-dropdown hidden text="MembersLI" right>
          <b-dropdown-item>Wishlist</b-dropdown-item>
          <b-dropdown-item>Dance Notes</b-dropdown-item>
          <b-dropdown-item>Dance Positions</b-dropdown-item>
        </b-nav-item-dropdown>
         <div hidden> 
            <b-navbar-nav class="ml-auto">
              <b-nav-item>Events</b-nav-item>
              <b-nav-item>Wishlist</b-nav-item>
              <b-nav-item>Dance Notes</b-nav-item>
            </b-navbar-nav>
          </div>
        <b-nav-item><router-link to="/Contact" tag="li">Contact</router-link></b-nav-item>
        <b-nav-item href="#"><span v-if="isLoggedIn"> <a @click="logout">Logout</a></span></b-nav-item>        
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>  
</template>


<script>
import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

export default {
  name: "NavBar",
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn; 
    },
    isAdmin(){
      return this.$store.getters.isLoggedIn && this.$store.getters.getAdminStatus; 
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })
    },
    goToAdmin(){
      this.$router.push({path: '/Admin'})
    }
  },
};
</script>

<style>
@import "./../design/navBar.css";

</style>