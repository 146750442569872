<template>
<div>
    <div v-show="displayLightbox">
        <gallery-lightbox :images="images" :index="currIndex" @close="onClose"></gallery-lightbox>
    </div>
    <div v-show="!displayLightbox">    
        <custom-gallery :images="images" @click="onClickChild"></custom-gallery>      
    </div>
</div>

</template>

<script>
//import FileGalleryVue from '../components/fileGalleryVue.vue'
//import fileGallery from './../components/fileGallery'
//import FBGallery from './../components/FBGallery'
import customGallery from './../components/customGallery'
import galleryLightbox from './../components/galleryLightbox'



export default {
    name: 'Gallery',
    components: {
       customGallery,
       galleryLightbox
    },
    data() {
        return {
            displayLightbox: false,
            images:[
              require('../images/Gallery/Originals/19420810_10211081427444013_1228952855533279304_n.jpg'),
              require('../images/Gallery/Originals/19990500_10154811586968589_5346791318577386051_n.jpg'),
              require('../images/Gallery/Originals/34442909_10156605067549258_8270527658131980288_n.jpg'),
              require('../images/Gallery/Originals/photo2.jpg'),
              require('../images/Gallery/Originals/photo3.jpg'),
              require('../images/Gallery/Originals/photo4.jpg'),
              require('../images/Gallery/Originals/photo5.jpg'),
              require('../images/Gallery/Originals/photo6.jpg'),
              require('../images/Gallery/Originals/photo7.jpg'),
              require('../images/Gallery/Originals/photo8.jpg'),
              require('../images/Gallery/Originals/photo9.jpg'),
              require('../images/Gallery/Originals/photo10.jpg'),
              require('../images/Gallery/Originals/photo11.jpg'),
              require('../images/Gallery/Originals/photo12.jpg'),
              require('../images/Gallery/Originals/photo13.jpg'),
              require('../images/Gallery/Originals/photo14.jpg'),
              require('../images/Gallery/Originals/photo15.jpg'),
              require('../images/Gallery/Originals/photo16.jpg'),
              require('../images/Gallery/Originals/photo17.jpg'),
              require('../images/Gallery/Originals/photo18.jpg'),
              require('../images/Gallery/Originals/photo19.jpg'),
              require('../images/Gallery/Originals/photo20.jpg'),
              require('../images/Gallery/Originals/photo21.jpg'),
              require('../images/Gallery/Originals/photo22.jpg'),
              require('../images/Gallery/Originals/photo23.jpg'),
              require('../images/Gallery/Originals/photo24.jpg'),
              require('../images/Gallery/Originals/photo25.jpg'),
              require('../images/Gallery/Originals/photo26.jpg'),
              require('../images/Gallery/Originals/photo27.jpg'),
              require('../images/Gallery/Originals/photo28.jpg'),
              require('../images/Gallery/Originals/photo29.jpg'),
              require('../images/Gallery/Originals/photo30.jpg'),              
              require('../images/Gallery/Originals/photo31.jpg'),
              require('../images/Gallery/Originals/photo32.jpg'),
              require('../images/Gallery/Originals/photo33.jpg'),
              require('../images/Gallery/Originals/photo34.jpg'),
              require('../images/Gallery/Originals/photo35.jpg'),
              require('../images/Gallery/Originals/photo36.jpg'),
              require('../images/Gallery/Originals/photo37.jpg'),
              require('../images/Gallery/Originals/photo38.jpg'),
              require('../images/Gallery/Originals/photo39.jpg'),
              require('../images/Gallery/Originals/photo40.jpg'),
              require('../images/Gallery/Originals/photo41.jpg'),
              require('../images/Gallery/Originals/photo42.jpg'),
              require('../images/Gallery/Originals/photo43.jpg'),
              require('../images/Gallery/Originals/photo44.jpg'),
              require('../images/Gallery/Originals/photo45.jpg'),
              require('../images/Gallery/Originals/photo46.jpg'),
              require('../images/Gallery/Originals/photo47.jpg'),
              require('../images/Gallery/Originals/photo48.jpg'),
              require('../images/Gallery/Originals/photo49.jpg'),
              require('../images/Gallery/Originals/photo50.jpg'),
              require('../images/Gallery/Originals/photo51.jpg'),
              require('../images/Gallery/Originals/photo52.jpg'),
              require('../images/Gallery/Originals/photo53.jpg'),
              require('../images/Gallery/Originals/photo54.jpg')
          ],
            currIndex: 0
        }
    },
    methods: {
        onClickChild(index) 
        {
            this.currIndex = parseInt(index);
            this.displayLightbox = true;   
        },
        onClose()
        {
            this.currIndex = 0;
            this.displayLightbox = false;
            this.$forceUpdate();
        }
    },
}
</script> 