<template>
<div>
    <div class="card-header py-2 mx-3 my-3 mb-4 d-flex justify-content-between rounded align-items-center">
      <div ></div>
      <div ><h3 class="font-weight-bold">Members</h3></div>
      <div>
          <b-button>
              <b-img  width=30px height=30px :src="require('../images/addIcon2.png')" @click="onAddMember">
              </b-img>
            </b-button>
        </div>
    </div> 
    <v-data-table 
        :headers="headers"
        :items="members"
        :loading="loadingData" loading-text="Loading member details - Please wait"
        fixed-header
        @click:row="onEditMember"
        @page-count="pageCount = $event"
        :items-per-page="30" 
        :page.sync="page"       
         >
         <template v-slot:item.subsPaid="{item}">
             <v-simple-checkbox v-model="item.subsPaid" @click="onSubsPaid(item)" :ripple="false"></v-simple-checkbox>
         </template>
         <template v-slot:item.actionMember="row">
          <v-btn icon >
            <v-icon>mdi-lead-pencil</v-icon>
          </v-btn>
          <v-btn icon v-on:click="onDeleteMember(row.item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
          
        </template>
    </v-data-table>    
    <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination> 
    </div>    
    <div class="alert alert-danger" style="text-align:center" role="alert" v-show="errorMessage">{{ errorMessage }}</div>
    <v-btn color="blue-grey" elevation="10" @click="gotoAdmin">Back</v-btn>
    </div>  
</template>

<style lang="css" scoped>
  @import "./../design/general.scss";
  
::v-deep td{
  cursor:pointer;
  white-space: nowrap;
}
</style>
<script>
import { data } from '../shared/data'

export default {
    name: 'MembersList',
    data() {
        return {            
            members: [],
            loadingData: false,
            errorMessage: '',
            headers: [
                {text: "MemberId",  value:"memberId",   sortable:false, align:'d-none'}, //invisible
                {text: "Name",      value:"name",       sortable:true,  align:'center'},
                {text: "Email",     value:"email",      sortable:false, align:'center'},
                {text: "Type",      value:"type",       sortable:true,  align:'center'},
                {text: "Subs Paid", value:"subsPaid",   sortable:true,  align:'center'},
                {text: "Actions",   value:"actionMember", sortable:false, align:'center'}
            ],
            page: 1,
            pageCount:  0,
            deleteMember: false
        }
    },
    async created () {        
        console.log('loading member details');      
        await this.loadMemberDetails();
    },
    methods: {
        async loadMemberDetails()
        {
            this.members = [];
            this.loadingData = true;
            console.log('requesting member details');
            var memberDetails = await data.getMemberDetails();
            this.loadingData = false;
            if(data.hasError())
            {
                this.errorMessage = "ERROR - " + data.getErrorMessage();
                return;
            }

            this.members = memberDetails.membersData.members;
            if(this.members.length <= 0)
            {
                this.errorMessage = "The team currently has no members";
            }
        },
        onAddMember()
        {
            console.log("Go to addEditMember page for new member");
            this.$router.push({name: 'AddEditMember', params:{type:'add'}});
        },
        onEditMember(rowData)
        {
            if(this.deleteMember)
            {
                this.deleteMember = false;
                return;
            }
                
            console.log("Go to addEditMember page to edit member");
            this.$router.push({name: 'AddEditMember', params:{type:'edit', member:rowData}});
        },
        async onDeleteMember(rowData)
        {
            this.deleteMember = true;
            console.log("deleting Member " + rowData.name);
            //delete member from database
            var success = await data.deleteMember(rowData.memberId);
            if(success)
            {
                //delete member from the table
                var index = this.members.findIndex(e => e.memberId == rowData.memberId);
                this.members.splice(index, 1);

                this.$confirm(rowData.name + " has been successfully deleted", 'Success', 'success');
            }
            else
            {
                this.$confirm("Failed to delete member " + rowData.name, 'Error', 'error');
                console.log("Error " + data.getErrorMessage());
            }
        },
        onSubsPaid(rowData)
        {
            console.log("update subs paid status to " + rowData.subsPaid + " for " + rowData.name);
            data.postSubsPaidStatus(rowData.memberId, rowData.subsPaid);
        },
        gotoAdmin()
        {
            this.$router.push({name: 'Admin'});
        },
    },
    
}
</script>