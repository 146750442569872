<template>
  <div class="container">
    <div class="title titleSingle">
      <div></div>
      <div><h3 class="titleText">Admin</h3></div>
      <div></div>
    </div>
    <div class="displayTextbox wrapper">
            <v-card-title primary-title style="padding-top:10px">
            <div style="width:100%;">
                <h2 style="text-align:center; font-size:2.5vw">Welcome to the Admin Area</h2>
            </div>
        </v-card-title>
        <v-card-subtitle style="font-size:1.2vw"> Please select what you would like to do</v-card-subtitle>
        <v-card-text style="text-align:left"><h2 class="optionTitle"> Events </h2>
            <v-card-actions>
                <v-row>
                    <v-col>
                        <v-btn color="brown lighten-2" elevation=10 @click="editEvent()" class="btnStyling">Edit</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="brown lighten-2" elevation="10" @click="addEvent()" class="btnStyling">Add</v-btn>            
                    </v-col>
                    <v-col>
                        <v-btn color="brown lighten-2" elevation="10" @click="confirmClearEvents()" class="btnStyling">Delete historic events</v-btn>
                    </v-col>                    
                </v-row>
            </v-card-actions>
        </v-card-text>
        <v-card-text style="text-align:left;"><h2 class="optionTitle"> Members </h2>
            <v-card-actions style="text-decoration:none">
                <v-row>                    
                    <v-col>
                        <v-btn color="brown lighten-2" elevation="10" @click="editMember()" class="btnStyling">Edit details</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="brown lighten-2" elevation="10" @click="addMember()" class="btnStyling">Add new member</v-btn>            
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card-text>
        <div class="alert alert-primary" style="text-align:center" role="alert" v-show="message">{{ message }}</div>
        <div class="alert alert-danger" style="text-align:center" role="alert" v-show="errorMessage">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import { data } from "../shared/data";

export default {
    name:"Admin",
    data() {
        return {
            location: 'home',
            errorMessage:'',
            message: ''
        }
    },
    async created() {   
        this.location = 'home';                
  },
    methods: {
        addEvent() {
            console.log('goto add events');
            this.$router.push({name:'addEditEvent', params:{type:'add'}});
        },
        editEvent(){
            console.log('goto edit events');
            this.$router.push({name: 'InvitedEvents'});
        },
        async confirmClearEvents()
        {
            var deleteConfirmed = 
            await this.$confirm("Are you sure?", "Delete historic events", "question");
            if(deleteConfirmed)
            {
                this.clearEvents();
            }
        },
        async clearEvents()
        {
            console.log('Deleting all historic events');
            await data.deleteOldEvents();
            if(data.hasError())
            {
                this.errorMessage = "Failed to delete historic events with error ( " + data.getErrorMessage + ")";
                this.$confirm("Failed to delete historic events", "Error", "error");
                return;
            }
            this.message = "Historic events successfully deleted"; 
            this.$confirm("All historic events have been deleted", "Success", "success");           
        },
        editMember()
        {
            console.log('Go to list of all Members');
            this.$router.push({name: 'MembersList'});
        },
        addMember()
        {
            console.log("Go to addEditMember page for new member");
            this.$router.push({name: 'AddEditMember', params:{type:'add'}});
        }
    },
}
</script>

<style scoped>
@import "./../design/general.scss";

</style>