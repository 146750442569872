<template>
  <div class="container">
    <div class="title titleSingle">
      <div></div>
      <div><h3 class="titleText">Contact Details</h3></div>
      <div></div>
    </div>
    <div class="displayTextbox">
      <div class="textBlocks">
        If you wish to contact Mary Rose and Royal George please do so through
        either our email, facebook or instagram accounts and we will get back to
        you as soon as we can.
        <br />
      </div>
      <div class="textBlock connectBlock">
        <strong class="subheading">Come connect with us</strong>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mx-4"
          style="text-decoration: none"
          href="https://www.facebook.com/maryroseandroyalgeorge/"
        >
          <v-icon size="24px">mdi-facebook</v-icon>
        </v-btn>
        <v-btn
          icon
          class="mx-4"
          href="https://www.instagram.com/maryroseandroyalgeorge"
        >
          <v-icon size="24px">mdi-instagram</v-icon>
        </v-btn>
        <v-btn icon class="mx-4" href="mailto:maryroseroyalgeorge@gmail.com">
          <v-icon size="24px">mdi-email</v-icon>
        </v-btn>
        <br />
      </div>
      <div class="textBlocks">
        Alternatively if you wish to give the dancing or music a go or just want
        to come along and watch, come along to one of our practices.
        <br /><br/>
        We would love to see you.
      </div>
      <div class="textBlocks connectBlock">
        <b>Every Monday - 19:30 - 21:30 <br /><br/>
          Venue:  <br />
          Fratton Railway Social Club, <br />Portsmouth,<br />
          PO1 5BL <br />
        </b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style>
@import "./../design/general.scss";

.connectBlock {
  padding: 1em 1em 1em 1em;
}
</style>