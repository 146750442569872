import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/authStore.js'
import Home from '../views/Home.vue'
import Members from '../views/Members.vue'
import Login from '../views/Login.vue'
import Attendance from '../views/Attendance.vue'
import EventPage from '../views/Events.vue'
import Admin from '../views/Admin.vue'
import addEditEvent from '../views/addEditEvent.vue'
import InvitedEvents from '../views/InvitedEvents.vue'
import Gallery from '../views/Gallery.vue'
import MembersList from '../views/MemberList.vue'
import addEditMember from '../views/addEditMember.vue'
import contact from '../views/contact.vue'
import PageNotFound from '@/views/PageNotFound.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: Home,
    component: Home,
    meta: {
      title: 'Mary Rose & Royal George'
    }
  },
  {
      path: '/Home',
      name: Home,
      component: Home,
      meta: {
        title: 'Mary Rose & Royal George'
      }
  },
  {
    path: '/About',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'About'
    }
  },
  {
    path:'/Events',
    name: 'Events',
    component: EventPage,
    meta:{title: 'Events'}
    
  },
  {
    path:'/Members',
    name: 'Members',
    component: Members,
    meta:{
      requiresAuth: true,
      title: 'Members Area'
    }
  },
  {
    path:'/Gallery',
    name: 'Gallery',
    component: Gallery,
    meta: {
      title: 'Gallery'
    }
  },
  {
    path:'/Login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Members Area'
    }  
  },
  {
    path:'/Members/Attendance',
    name: 'Attendance',
    component: Attendance,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Members Area'
    }
  },
  {
    path:'/Contact',
    name: 'Contact',
    component: contact,
    meta: {
      title: 'Contact'
    }
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      title: 'Admin Area'
    }
  },
  {
    path: '/Admin/AddEditEvent',
    name: 'addEditEvent',
    component: addEditEvent,
    meta: {
      requiresAuth: true,
      title: 'Admin Area'
    }
  },
  {
    path:'/admin/InvitedEvents',
    name: 'InvitedEvents',
    component: InvitedEvents,
    meta: {
      requiresAuth: true,
      title: 'Admin Area'
    }
  },
  {
    path:'/Admin/MembersList',
    name: 'MembersList',
    component: MembersList,
    meta: {
      requiresAuth: true,
      title: 'Admin Area'
    }
  },
  {
    path:'/Admin/addEditMember',
    name: 'AddEditMember',
    component: addEditMember,
    meta: {
      requiresAuth: true,
      title: 'Admin Area'
    }
  },
    // fallback route
  // catch all redirect
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: PageNotFound,
  },
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {
  if(to.matched.some(record => record.meta.requiresAuth))
  {
    console.log("login status = " + store.getters.isLoggedIn);
      if(store.getters.isLoggedIn)
      {
        next();
        return;
      }
      else
      {
        next('/login');
      }
  }
  else
  {

    console.log("go to next =" + to.meta.title);
    next();
  }
});

router.beforeEach((to, from, next) =>{
  store.dispatch('navigate', {to});
  const title = to.meta.title || '';
  document.title = title;
  next();

});
export default router