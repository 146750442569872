<template>
  <div class="container">
    <div class="title titleSingle">
      <div></div>
      <div><h3 class="titleText">Upcoming Events</h3></div>
      <div></div>
    </div>
    <div class="displayTextbox wrapper">
      <div>
        <v-data-table
          :headers="headers"
          :items="events"
          calculate-widths:true
          fixed-header:true
          :loading="loadingEvents"
          loading-text="Please wait, we are loading the events"
          :custom-sort="customDateSort"
        >          
          <template v-slot:item.date="{ item }">
            <span> {{ getFriendlyDate(item) }}</span>
          </template>
          
          
          <template v-slot:no-data>
            <v-alert :value="true">
              There are no confirmed events in the MRRG calendar.
            </v-alert>
          </template>
        </v-data-table>
      </div>
      <div
        class="alert alert-primary"
        style="text-align: center"
        role="alert"
        v-show="message"
      >
        {{ message }}
      </div>
      <div
        class="alert alert-danger"
        style="text-align: center"
        role="alert"
        v-show="errorMessage"
      >
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
@import "./../design/general.scss";
@import "./../design/table.scss";

.wrapper {
  display: flex;
  flex-flow: column;
  margin-top: 50px;
}
.displayTextbox
{
  padding: 4em 1em 2em 1em;
}
</style>

<script>
import { data } from "../shared/data";
import { display } from "../shared/display";

export default {
  name: "ConfirmedEvents",
  data() {
    return {
      loadingEvents: false,
      events: [],
      message: "",
      errorMessage: "",
      headers: [
        {
          text: "Title",
          value: "title",
          sortable: true,
          align: "center",
          width: "20%",
        },
        {
            text: "Address",
            value: "address",
            sortable: false,
            align: "center",
            width: "20%"
        },       
        {
          text: "Date",
          value: "date",
          sortable: true,
          align: "center",
          width: "20%",
        },
        //{ text: "Address", value: "address", align:'center', width:"30%" }
      ],
    };
  },
  async created() {
    console.log("loading events");
    await this.loadEvents();
  },
  methods: {
    async loadEvents() {
      this.events = [];
      this.loadingEvents = true;
      this.message = "Please wait: Retrieving confirmed events";
      this.errorMessage = "";

      console.log("request events");

      var event = await data.getConfirmedEvents();
      this.loadingEvents = false;
      if (data.hasError()) {
        this.message = "";
        this.errorMessage = "ERROR - " + data.getErrorMessage();
        return;
      }

      this.events = event.confirmedEvents.events;
      console.log("returned events " + event.length);
      if (this.events.length <= 0) {
        this.message = "The team currently have no confirmed events.";
      }
      this.message = "";
    },
    getFriendlyDate(item) 
    {
      if(item.endDate !== null && item.endDate !== item.startDate)
      {
          // Fri 23
          var date = display.getFriendlyDate(item.startDate, "eee do");          
          if(!display.hasSameMonth(item.startDate, item.endDate))
          {
              // Fri 23 May
              date.concat(" ", display.getFriendlyDate(item.startDate, "MMM"));
          }

          //- 24 Jun 2022
          return date.concat(" - ", display.getFriendlyDate(item.endDate, "do MMM yy"));//, " " , display.getYear(item.endDate));        
      }
      else
      {
          return  display.getFriendlyDate(item.startDate);
      }      
    },
    sortDate(a, b, key, sortDesc) {
      return display.sortDate(a, b, key, sortDesc);
    },
    customDateSort(items, index, isDesc)
    {
       items.sort((a, b) => {
          if (index[0]=='date') {
            if (!isDesc[0]) {
                return new Date(b['startDate']) - new Date(a['startDate']);
            } else {
                return new Date(a['startDate']) - new Date(b['startDate']);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    }
  },
};
</script>

