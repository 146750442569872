<template>
          <div>          
            <div class="lightbox-wrapper" id="lightbox-wrapper">
            <div class="lightbox" id="lightbox">
                <div class="lightbox-header"></div>
                <div class="lightbox-numbers"></div>
                <button type="button" class="lightbox-close" aria-label="Close" @click="onClose"></button>
                <div class="lightbox-slides-wrapper">
                    <div class="lightbox-slide" data-state="prev">
                        <img class="lightbox-image" :src="images[currentIndex-1]" alt="" draggable="false">
                        <div class="spinner spinner-border" role="status"><span class="sr-only">Loading... </span></div>
                    </div>
                    <div class="lightbox-slide" data-state="current">
                        <img class="lightbox-image" :src="images[currentIndex]" alt="" draggable="false">
                        <div class="spinner spinner-border" role="status"><span class="sr-only">Loading... </span></div>
                    </div>
                    <div class="lightbox-slide" data-state="next">
                        <img class="lightbox-image" :src="images[currentIndex+1]" alt="" draggable="false">
                        <div class="spinner spinner-border" role="status"><span class="sr-only">Loading... </span></div>
                    </div>
                </div>
                <div class="lightbox-arrow arrow-left" @click="onPrev"></div>
                <div class="lightbox-arrow arrow-right" @click="onNext"></div>
                <div class="lightbox-footer"></div>
            </div>            
            </div>
        
    </div>
</template>

<script>
export default {
    name: "GalleryLightbox",
    props: {
        images: {
            type: Array,            
            required: true
        },
        index:{
            type: Number,
            default: -1,
            required: true            
        }
    },    
    data() {
        return {
            wasSwiping: false,
            currentIndex: 0
        }        
    },
    mounted () {
        this.showInitialImage();        
    },
    watch:{
        index: function()
        {
            if(this.index != this.currentIndex)
            {
                this.currentIndex = this.index;
            }            
        }
    },
    methods: {
        showInitialImage() {
            if(this.images == undefined || this.images.length <= 0 || this.index == -1)
            {
                return;
            }
            this.currentIndex = this.index;            
        },        
        onClose()
        {
            this.$emit("close");
        },
        onPrev()
        {
            this.currentIndex > 0 ? 
                this.currentIndex -= 1 : this.currentIndex = this.images.length - 1;            
        },
        onNext()
        {
            this.currentIndex < this.images.length -1 ? 
                this.currentIndex += 1 : this.currentIndex = 0;            
        }

    },
    
}
</script>

<style>
@import "./../design/galleryLightbox.css";
</style>